<template>
  <v-dialog justify="center" :value="value" v-if='!init' persistent max-width="450">
    <v-card color="fill" v-if="!init">
      <v-spacer/>
      <v-card-title class="font-weight-light">Ad Status</v-card-title>
      <v-card-text>
        <span :class="body">
          {{ message }}
        </span>
        <v-row v-if="rejectedAd">
          <v-col>
            <v-textarea outlined dense background-color="input" placeholder="Please leave brief rejection reason..." no-resize rows="6" v-model="note.note_text"/>
          </v-col>
        </v-row>
      </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn :class="body" text @click.stop="$emit('update:value', false)">Cancel</v-btn>
          <v-btn :class="body" :loading="loading" :disabled="rejectedAd && !note.note_text" class="green white--text ml-2" @click="updateStatus">Confirm
        </v-btn>
        </v-card-actions>
      <!-- </section> -->
    </v-card>
  </v-dialog>
</template>
<script>
import { displayHelpers } from '@/mixins/display-helpers'
export default {
  name: 'AdStatus',
  props: ['value', 'selectedAd', 'direction', 'getAdList'],
  mixins: [displayHelpers],
  data: () => ({
    checkStatus:{},
    note: {},
    loading: false,
    id: 'ADPLANNING.STATUS',
    currentStatus:'',
    nextStatuses: [],
    init: true,
  }),
  computed: {
    rejectedAd () {
      return this.nextStatus?.constant === 'REJECTED'
    },
    isSubmitted () {
      return this.currentStatus?.constant === 'SUBMITTED'
    },
    nextStatus () {
      if (this.nextStatuses.length > 0 && this.currentStatus?.id) {
        if (this.isSubmitted) {
          let status
          if (['alliance-retail-group','pricechopper'].includes(this.$auth.tenant)) {
            status = this.direction === 'prev' ? 'DRAFT' : 'APPROVED'
          } else {
            status = this.direction === 'prev' ? 'REJECTED' : 'APPROVED'
          }
          return this.nextStatuses.find(s => s.constant === status)
        } else if (this.nextStatuses.length === 1) {
          return this.nextStatuses[0]
        }
      }
      return null
    },
    message () {
      if (this.selectedAd && this.nextStatus) {
        return `Update Status of ${this.selectedAd.ad_name} to ${this.nextStatus.name}?`
      }
      return ''
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.getStatus()
        }
      }
    },
  },
  created () {
    if (this.selectedAd.status === 'Error') {
      this.checkAd()
    }
  },
  methods: {
    async saveAdNote () {
      this.note.ad_id = this.selectedAd.id
      await this.$Ads.saveAdNote(this.note)
    },
    async checkAd() {
      await this.$Ads.checkAd(this.selectedAd.id).then(res => {
        this.checkStatus = res.data
      }).catch(err => {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Check Ad due to ${err?.response?.data?.message || err.message}` })
      })
    },
    async updateStatus () {
      /*
      if (this.checkStatus.result) {
        this.$store.dispatch('setSnackbar', { status: 'error', text: 'Please Correct Errors Before Changing Status' })
        return this.$emit('update:value', false)
      }
      */
      this.selectedAd.status = this.nextStatus.name
      if (this.selectedAd.ad_type) {
        this.selectedAd.ad_type = this.selectedAd.ad_type.id
      }
      this.loading = true
      try {
        if (this.rejectedAd) {
          await this.saveAdNote()
        }
        await this.$Ads.updateAd(this.selectedAd.id, this.selectedAd)
        this.$emit('refresh')
        this.$emit('update:value', false)
        this.$store.dispatch('setSnackbar', { status: 'success', text: 'Status Updated' })
      } catch (err) {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Update Status on ${err?.response?.data?.message || err.message}` })
        this.$emit('update:value', false)
      } finally {
        this.loading = false
      }
    },
    async getStatus() {
      const params = {
        constant: this.selectedAd.status.toUpperCase()
      }
      await this.$KeyValues.getKeyByConstant(this.id, params).then(res=>{
        this.currentStatus = res.data
      }).catch(err=>{
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to get Current Status on ${err?.response?.data?.message || err.message}` })
        this.$emit('update:value', false)
      })
      this.getNextStatus()
    },
    async getNextStatus() {
      try {
        const ad_kv = await this.$KeyValues.getKeyByConstant('ENTITY_TYPE', {
          constant: 'AD'
        })
        const workflow_id = (await this.$Status.getWorkflows(ad_kv.data.id)).data[0].id
        const params = { workflow_id: workflow_id, current_status_id: this.currentStatus.id }
        const res = await this.$Status.getNextStatus(params)
        this.nextStatuses = res.data
      } catch (err) {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to get Next Status on ${err?.response?.data?.message || err.message}` })
        this.$emit('update:value', false)
      }
      this.init = false
    }
  }
}
</script>
